import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import '../style.css';
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrollTarget, setScrollTarget] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    const scrollToAnchor = (anchor: string) => {
        const target = document.getElementById(anchor);
        if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleLinkClick = (path: string, anchor: string) => {
        if (location.pathname === path) {
            scrollToAnchor(anchor);
        } else {
            setScrollTarget(anchor);
            navigate(path);
        }
        setIsMenuOpen(false); // Закрыть меню после клика
    };

    useEffect(() => {
        if (scrollTarget) {
            scrollToAnchor(scrollTarget);
            setScrollTarget(null); // Сбросить целевой якорь после прокрутки
        }
    }, [location, scrollTarget]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className="container">
                <nav className="navbar">
                    <div className="logo">
                        <img src={logo} alt="OSA Consulting Logo"></img>
                        <span>OSA CONSULTING</span>
                    </div>
                    <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                        {isMenuOpen && (
                            <div className="logo-in-menu">
                                <img src={logo} alt="OSA Consulting Logo"></img>
                                <span>OSA CONSULTING</span>
                            </div>
                        )}
                        <ul>
                            <li>
                                <Link to="/" onClick={() => handleLinkClick('/', 'scope')}>Scope</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => handleLinkClick('/', 'ecosystem')}>Services</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => handleLinkClick('/', 'news')}>News</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => handleLinkClick('/', 'team')}>Team</Link>
                            </li>
                            {/*<li><a href="#" onClick={() => scrollToAnchor("contacts")}>Contacts</a></li>*/}
                        </ul>
                        <a href="https://forms.gle/NToovG249WV7TAzF7" className="contact-button header-button">Contact us</a>
                    </div>
                    <a href="https://forms.gle/NToovG249WV7TAzF7" className="contact-button">Contact us</a>
                    <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
