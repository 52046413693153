import React, {useEffect, useState} from 'react';
import axios from "axios";
import img from '../assets/banner2.png';
import banner_mini from '../assets/banner-mini2.png';
import banner_micro from '../assets/banner-micro2.png';
import Footer from "../components/Footer";
import Header from "../components/Header";
import Block from "../components/Block";
import {useSwipeable} from "react-swipeable";
import ResponsiveImage from "../components/ResponsiveImage";
import '../style.css';
import {useNavigate} from 'react-router-dom';
import {message} from "antd";
import CookiePopup from "../components/CookiePopup";
import checkmark from "../assets/checkmark.png";

interface NewsItem {
    id: number;
    name: string;
    date: string;
    text: string;
    imageUrl: string;
    youtubeUrl: string;
    podcast: string;
}

interface TeamItem {
    id: number;
    name: string;
    position: string;
    text: string;
    imageUrl: string;
    position2: string;
    number: number;
}

interface Testimonial {
    id: number;
    title: string;
    subtitle: string;
    sponsor: string;
    name: string;
    company: string;
    text: string;
}

interface StaticTextModel {
    id?: number;
    topSubscribeTitle: string;
    topSubscribeText: string;
    scopeTitle: string;
    scopeText: string;
    servicesTitle: string;
    servicesText: string;
    newsTitle: string;
    newsText: string;
    testimonialsTitle: string;
    testimonialsText: string;
    teamTitle: string;
    teamText: string;
    bottomSubscribeTitle: string;
    bottomSubscribeText: string;
}

const operational = [
    "A.I. & Machine Learning Implementation",
    "Audit Support",
    "Coaching & Talent Development",
    "Compliance Services & Training",
    "Data Architecture & Cloud Services",
    "Digital Infrastructure & Analytics Implementation",
    "Financial Analysis, Forecasting, and Budgeting",
    "International Entity Formation and Structuring",
    "Market  Research & Business Intelligence",
    "Tax & Banking Jurisdiction Optimization",
];
const tactical = [
    "Training & development",
    "Change  management",
    "Customer segmentation",
    "Sales training",
    "Sales  process design",
    "Business valuation",
    "Channel development",
    "Financial planning",
    "Channel development",
    "Financial planning",
    "Compliance services",
    "Systems integration",
    "CRM implementation",
    "Partnership development",
    "Entity  setup",
    "Lobbying",
    "Regulations",
];
const strategic = [
    "Capital Restructuring",
    "Fundraising Strategy & Investment Readiness",
    "Innovation Management & Technology Strategy",
    "Business Development & Sales Strategy",
    "Marketing & Brand Strategy",
    "Market Strategy & Expansion",
    "Negotiations Strategy",
    "Product, Pricing, and Customer Strategy",
    "Strategic Alliances & Partnerships",
    "Technology Strategy",
];

const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'


const Main: React.FC = () => {
    const [news, setNews] = useState<NewsItem[]>([]);
    const [people, setPeople] = useState<TeamItem[]>([]);
    const [visibleNewsCount, setVisibleNewsCount] = useState(3);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Состояние для отображения попапа
    const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null); // Выбранная новость для отображения в попапе
    const [isTeamPopupOpen, setTeamIsPopupOpen] = useState(false); // Состояние для отображения попапа
    const [selectedPeople, setSelectedPeople] = useState<TeamItem | null>(null); // Выбранная новость для отображения в попапе
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [openBlock, setOpenBlock] = useState<string | null>("left");
    const [name, setName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState('');
    const [staticTexts, setStaticTexts] = useState<StaticTextModel | null>(null);


    const navigate = useNavigate();


    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get<NewsItem[]>(`${backendUrl}/news`);
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };
        fetchNews();
    }, []);

    useEffect(() => {
        const fetchTeam = async () => {
            try {
                const response = await axios.get<TeamItem[]>(`${backendUrl}/team`);
                setPeople(response.data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };
        fetchTeam();
    }, []);

    useEffect(() => {
        // Замени URL на реальный эндпоинт для получения данных
        axios.get<Testimonial[]>(`${backendUrl}/testimonials`)
            .then(response => {
                setTestimonials(response.data);
            })
            .catch(error => console.error('Error fetching testimonials:', error));
    }, []);

    useEffect(() => {
        axios.get<StaticTextModel>(`${backendUrl}/static-texts/1`)
            .then(response => {
                setStaticTexts(response.data);
            })
            .catch(error => console.error('Error fetching static texts:', error));
    }, []);

    useEffect(() => {
        // Function to update the visibleNewsCount based on the screen width
        const updateVisibleNewsCount = () => {
            const width = window.innerWidth;
            if (width <= 426) {
                setVisibleNewsCount(2); // Mobile view
            } else if (width >= 1080) {
                setVisibleNewsCount(3); // Tablet view
            } else if (width <= 767) {
                setVisibleNewsCount(3); // Desktop view
            }
        };

        // Initial call to set the state based on the initial width
        updateVisibleNewsCount();

        // Event listener for window resize to update the state dynamically
        window.addEventListener('resize', updateVisibleNewsCount);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateVisibleNewsCount);
        };
    }, []);

    const handleShowMore = () => {
        setVisibleNewsCount(news.length);
    };

    const openPopup = (teamItem: TeamItem) => {
        setSelectedPeople(teamItem);
        setIsPopupOpen(true);
    };

    // Функция для закрытия попапа
    const closePopup = () => {
        setSelectedNews(null);
        setIsPopupOpen(false);
    };

    const openTeamPopup = (teamItem: TeamItem) => {
        setSelectedPeople(teamItem);
        setTeamIsPopupOpen(true);
    };

    // Функция для закрытия попапа
    const closeTeamPopup = () => {
        setSelectedPeople(null);
        setTeamIsPopupOpen(false);
    };

    const handlePrevClick = () => {
        if (testimonials.length > 0) {
            setCurrentIndex((currentIndex - 1 + testimonials.length) % testimonials.length);
        }
    };

    const handleNextClick = () => {
        if (testimonials.length > 0) {
            setCurrentIndex((currentIndex + 1) % testimonials.length);
        }
    };

    const handleClick = (block: string) => {
        setOpenBlock(openBlock === block ? null : block);
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNextClick,
        onSwipedRight: handlePrevClick,
    });

    const handleNewsItem = (id: number) => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/news/${id}`);
    };

    const handleEmail = async () => {
        const formData = new FormData();
        const formattedData = {
            name: name,
        };
        formData.append('email', JSON.stringify(formattedData));

        try {
            await axios.post(`${backendUrl}/email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setSubmitted(true);
            setSubmittedEmail(name); // Сохранить введенный email после успешной отправки
            setName(''); // Очистить значение инпута
        } catch (err) {
            message.error('Error sending email');
        }
    };


    return (
        <div>
            <Header/>
            <CookiePopup/>
            <div className="main">
                <div className="banner-container">
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="banner-image-container">
                                <div className="banner-image"></div>
                                <div className="tags-image"></div>
                                <div className="tags">
                                    <div className="aerospace"></div>
                                    <div className="energy"></div>
                                    <div className="finteh"></div>
                                    <div className="logistics"></div>
                                    <div className="ngo"></div>
                                    <div className="robotics"></div>
                                    <div className="soft"></div>
                                    <div className="telecom"></div>
                                </div>
                            </div>
                            <div className="text-input-container">
                                <div className="text-h2">
                                    <p style={{display: staticTexts && staticTexts.topSubscribeTitle ? 'block' : 'none'}}>
                                        {staticTexts?.topSubscribeTitle}
                                    </p>
                                    <a style={{display: staticTexts && staticTexts.topSubscribeText ? 'block' : 'none'}}>
                                        {staticTexts?.topSubscribeText}
                                    </a>
                                </div>
                                <div className="input-container">
                                    <input
                                        type="email"
                                        placeholder="Your email"
                                        className="email-input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <button className="submit-button desktop" onClick={handleEmail}>Submit
                                    </button>
                                </div>
                                {submitted && (
                                    <div className="success-message">
                                        <img src={checkmark} className="checkmark" alt="Checkmark"></img>
                                        <span>You’re in! Check {submittedEmail}</span>
                                    </div>
                                )}
                                <button className="submit-button mobile-button" onClick={handleEmail}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scope-container" id="scope">
                    <div className="container">
                        <div className="scope-title">
                            <h1>{staticTexts?.scopeTitle}</h1>
                            <p>{staticTexts?.scopeText}</p>
                        </div>

                        <div className="scope-image"></div>
                    </div>
                </div>
                <div className="lifecycle-container" id="ecosystem">
                    <div className="container">
                        <div className="lifecycle-title">
                            <h1>{staticTexts?.servicesTitle}</h1>
                            <p>{staticTexts?.servicesText}</p>
                        </div>

                        <div className="lifecycle-image">
                            <Block
                                id="left"
                                title="Operational"
                                content={operational}
                                isOpen={openBlock === 'left'}
                                onClick={() => handleClick('left')}
                            />
                            {/*<Block*/}
                            {/*    id="middle"*/}
                            {/*    title="Tactical"*/}
                            {/*    content={tactical}*/}
                            {/*    isOpen={openBlock === 'middle'}*/}
                            {/*    onClick={() => handleClick('middle')}*/}
                            {/*/>*/}
                            <Block
                                id="right"
                                title="Strategic"
                                content={strategic}
                                isOpen={openBlock === 'right'}
                                onClick={() => handleClick('right')}
                            />
                        </div>
                    </div>
                </div>
                <div className="news-container" id="news">
                    <div className="container">
                        <div className="news-title">
                            <h1>{staticTexts?.newsTitle}</h1>
                            <p>{staticTexts?.newsText}</p>
                        </div>
                        <div className="news">
                            {news
                                .slice()
                                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Сортировка новостей по дате в порядке убывания
                                .slice(0, visibleNewsCount)
                                .map((item: NewsItem) => (
                                    <div className="news-item" key={item.id} onClick={() => handleNewsItem(item.id)}>
                                        <img src={item.imageUrl} alt="News"/>
                                        <p>{item.name}</p>
                                        <a>{item.date}</a>
                                    </div>
                                ))}
                        </div>

                        {visibleNewsCount < news.length && (
                            <div className="show-more-button-container">
                                <button className="show-more" onClick={handleShowMore}>See all articles</button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="testimonials-container">
                    <div className="container">
                        <div className="testimonials-title">
                            <h1>{staticTexts?.testimonialsTitle}</h1>
                            <p>{staticTexts?.testimonialsText}</p>
                        </div>
                        <div {...handlers} className="testimonials-content">
                            {testimonials.length > 0 && (
                                <>
                                    <div className="testimonials-content-title">
                                        <h1>{testimonials[currentIndex].title}</h1>
                                        <p>{testimonials[currentIndex].subtitle}</p>
                                    </div>
                                    <div className="testimonials-content-post">
                                        <div className="post-title">
                                            <img src={testimonials[currentIndex].sponsor}
                                                 style={{display: testimonials[currentIndex].sponsor ? 'flex' : 'none'}}
                                                 alt=""/>
                                            <div className="post-title-text">
                                                <h1>{testimonials[currentIndex].name}</h1>
                                                <p>{testimonials[currentIndex].company}</p>
                                            </div>
                                        </div>
                                        <a>{testimonials[currentIndex].text}</a>
                                    </div>
                                    <div className="navigation">
                                        <div className="indicators">
                                            {testimonials.map((_, index) => (
                                                <span
                                                    key={index}
                                                    className={`indicator ${currentIndex === index ? 'active' : ''}`}
                                                    onClick={() => setCurrentIndex(index)}
                                                ></span>
                                            ))}
                                        </div>
                                        <div className="arrows">
                                            <div className="arrow left" onClick={handlePrevClick}></div>
                                            <div className="arrow right" onClick={handleNextClick}></div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {testimonials.length === 0 && <p>Loading...</p>}
                        </div>
                    </div>
                </div>
                <div className="work-container">
                    <div className="work"></div>
                </div>
                <div className="team-container" id="team">
                    <div className="container">
                        <div className="team-title">
                            <h1>{staticTexts?.teamTitle}</h1>
                            <p>{staticTexts?.teamText}</p>
                        </div>
                        <div className="team-people">
                            {people
                                .sort((a, b) => a.number - b.number) // Сортировка по item.number
                                .map((item) => (
                                    <div className="team-people-item" key={item.id} onClick={() => openPopup(item)}>
                                        <div className="image-container">
                                            <img src={item.imageUrl} alt="people"/>
                                        </div>
                                        <div className="text-overlay">
                                            <p>{item.name}</p>
                                            <p className="pb-2">{item.position}</p>
                                            <a>{item.position2}</a>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        {isPopupOpen && selectedPeople && (
                            <div className="popup">
                                <div className="popup-content">
                                    <span className="close-icon" onClick={closePopup}>&times;</span>
                                    <div className="popup-title">
                                        <h2>{selectedPeople.name}</h2>
                                        <a>{selectedPeople.position}</a><br/>
                                        <span>{selectedPeople.position2}</span>
                                    </div>
                                    <p>{selectedPeople.text}</p>
                                </div>
                            </div>
                        )}
                        <div className="inform-container" id="contacts">
                            <ResponsiveImage
                                imgSmall={banner_micro}
                                imgMedium={banner_mini}
                                imgLarge={img}
                                alt="Informative Image"
                            />
                            <div className="inform-text-input-container">
                                <div className="inform-text-h2">
                                    <p>{staticTexts?.bottomSubscribeTitle}</p>
                                    <a>{staticTexts?.bottomSubscribeText}</a>
                                </div>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        placeholder="Your email"
                                        className="email-input"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <button className="submit-button desktop" onClick={handleEmail}>Submit
                                    </button>
                                </div>
                                {submitted && (
                                    <div className="success-message">
                                        <img src={checkmark} className="checkmark" alt="Checkmark"></img>
                                        <span>You’re in! Check {submittedEmail}</span>
                                    </div>
                                )}
                                <button className="submit-button mobile-button" onClick={handleEmail}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default Main;
