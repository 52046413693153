import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const CookiePopup: React.FC = () => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const handlePrivacy = () => {
        navigate(`/privacy-policy/`);
    };

    useEffect(() => {
        console.log("useEffect is called");
        const hasAcceptedCookies = localStorage.getItem('acceptedCookies');
        if (!hasAcceptedCookies) {
            setShowPopup(true);
            console.log("Cookie popup is shown for the first time.");
        } else {
            console.log("Cookie popup is shown before");
        }
    }, []);

    const handleAcceptCookies = (action: string) => {
        localStorage.setItem('acceptedCookies', action);
        setShowPopup(false);
        console.log(`User has ${action === 'accepted' ? 'accepted' : 'rejected'} cookies.`);
    };

    if (!showPopup) {
        return null;
    }

    return (
        <div className="cookie-popup show">
            <div className="cookie-popup-content">
                <p>We use cookies to enhance your browsing experience, serve personalized content and analyze our traffic. By choosing appropriate option, you consent or refuse to our use <a onClick={handlePrivacy}>of cookies.</a></p>
                <div className="button-block">
                    <button className="decline" onClick={() => handleAcceptCookies('rejected')}>Reject All</button>
                    <button className="accept" onClick={() => handleAcceptCookies('accepted')}>Accept All</button>
                </div>
            </div>
        </div>
    );
};

export default CookiePopup;
