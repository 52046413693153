import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, Form, Input, DatePicker, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import "../style.css"
import {useNavigate} from "react-router-dom";

interface Testimonial {
    id: number;
    title: string;
    subtitle: string;
    sponsor: string;
    name: string;
    company: string;
    text: string;
}

const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const Testimonials: React.FC = () => {
    const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentNews, setCurrentNews] = useState<Testimonial | null>(null);
    const [fileList, setFileList] = useState<any[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get<Testimonial[]>(`${backendUrl}/testimonials`);
            setTestimonials(response.data);
            setLoading(false);
        } catch (error) {
            message.error('Error fetching testimonials');
        }
    };

    const handleAddNews = () => {
        form.resetFields();
        setCurrentNews(null);
        setIsEditMode(false);
        setFileList([]);
        setIsModalVisible(true);
    };

    const handleDeleteNews = async (id: number) => {
        try {
            await axios.delete(`${backendUrl}/testimonials/${id}`);
            message.success('Testimonial deleted successfully');
            fetchNews();
        } catch (error) {
            message.error('Error deleting testimonial');
        }
    };

    const handleFormSubmit = async (values: any) => {
        const {image, ...newsData} = values;

        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('img', fileList[0].originFileObj);
        }

        const formattedData = {
            name: newsData.name,
            title: newsData.title,
            subtitle: newsData.subtitle,
            company: newsData.company,
            text: newsData.text,
        };
        formData.append('testimonials', JSON.stringify(formattedData));

        try {
            if (isEditMode && currentNews) {
                await axios.put(`${backendUrl}/testimonials/${currentNews.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Testimonial updated successfully');
            } else {
                await axios.post(`${backendUrl}/testimonials`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Testimonial added successfully');
            }
            fetchNews();
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error saving testimonial');
        }
    };

    const handleUploadChange = ({fileList}: any) => {
        setFileList(fileList);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        }, {
            title: 'Subtitle',
            dataIndex: 'subtitle',
            key: 'subtitle'
        }, {
            title: 'Company',
            dataIndex: 'company',
            key: 'company'
        },
        {
            title: 'Image',
            dataIndex: 'sponsor',
            key: 'sponsor',
            render: (imageUrl: string) => (
                <img src={imageUrl} alt="news" style={{width: '100px'}}/>
            )
        },
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text',
            render: (text: string) => (
                <div style={{maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {text}
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: Testimonial) => (
                <>
                    <Button onClick={() => handleDeleteNews(record.id)} type="default">
                        Delete
                    </Button>
                </>
            )
        }
    ];

    const handleToTeam = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/team`);
    };
    const handleToNews = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/news`);
    };
    const handleToEmail = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/email`);
    };
    const handleToStaticText = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/static-text`);
    };
    const navigate = useNavigate();

    return (
        <div className="admin-panel-container">
            <h1>Testimonials</h1>
            <Button type="default" onClick={handleAddNews} style={{marginBottom: 16, marginRight: 5}}>
                Add Testimonial
            </Button>
            <Button type="default" onClick={handleToTeam} style={{marginBottom: 16, marginRight: 5}}>
                Team
            </Button>
            <Button type="default" onClick={handleToNews} style={{marginBottom: 16, marginRight: 5}}>
                News
            </Button>
            <Button type="default" onClick={handleToEmail} style={{marginBottom: 16}}>
                Email
            </Button>
            <Button type="default" onClick={handleToStaticText} style={{marginBottom: 16}}>
                Static Text
            </Button>
            <Table columns={columns} dataSource={testimonials} rowKey="id" loading={loading}/>

            <Modal
                title={isEditMode ? 'Edit News' : 'Add News'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item name="title" label="Left small Title">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="subtitle" label="Left font bigger Title">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="name" label="Name">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="company" label="Company">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="text" label="Text">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="image" label="Logo">
                        <Upload
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleUploadChange}
                            listType="picture"
                        >
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" htmlType="submit">
                            {isEditMode ? 'Update' : 'Add'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Testimonials;
