import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Typography} from "antd";

const {Title, Paragraph, Text, Link} = Typography;


const LegalDisclaimer: React.FC = () => {
    return (
        <div>
            <Header/>
            <div className="container">
                <div style={{textAlign: "left"}}>
                    <h1 style={{fontSize: "32px", marginTop: "30px", marginBottom: "20px"}}>LEGAL DISCLAIMER</h1>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "10px"}}>This Website is the property of OSA
                            Consulting LLC. <br/><br/>
                            By using or accessing this Website, the User accepts the Terms of this Legal
                            Disclaimer. <br/>
                            The User also accepts the Terms of this Legal Disclaimer in the event of changes to this
                            Website’s content. <br/><br/>
                            From time to time, OSA Consulting reserves the right to revise and amend this Legal
                            Disclaimer. <br/><br/><br/>
                        </p>
                    </Paragraph>

                    <h1 style={{fontSize: "32px", marginBottom: "20px"}}>Warranties and Liability</h1>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "10px"}}>The Website has been implemented and is made
                            available on an “as is” basis. OSA Consulting makes no representation or guarantee with
                            respect to the accuracy or completeness of the provided information. Although the content
                            has been produced with the best professional approach, the content may change from time to
                            time however we make no commitment to modify the website content, to update the website or
                            to keep it accessible. <br/><br/>
                            OSA Consulting does not commit itself that the tools, software and servers enabling this
                            Website and its access are free of bugs/errors/virus. We take for granted the User’s
                            acceptance of these potential risks and that each User will take the necessary precaution to
                            protect him/herself against such situations. It is therefore highly recommended that each
                            User scans any files before downloading. <br/><br/>
                            The content and use of this Website does not constitute any kind of professional advice or
                            even any sort of recommendation (neither by law or otherwise). <br/><br/>
                            OSA Consulting is not liable for any kind of damage (such as but not limited to loss of
                            contracts, loss of reputation, data, business relationships), whatsoever the nature may be,
                            whether or not a connection with the use of this Website can be made. <br/><br/><br/>
                        </p>
                    </Paragraph>

                    <h1 style={{fontSize: "32px", marginBottom: "20px"}}>General Agreement</h1>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "10px"}}>In case parts of this Disclaimer are
                            considered illegal, invalid or unenforceable those other parts still apply. Parts considered
                            illegal will be immediately modified to make them legally binding. <br/><br/>
                            All content on this Website, including but not limited to its design, content (text and
                            graphics), software, technical drawings, configurations, other files are, either Copyright
                            OSA Consulting or, credited otherwise. <br/><br/>
                            The content may not be modified, copied, distributed, framed, reproduced, republished,
                            downloaded, displayed, posted, transmitted or sold in any form or by any means, in whole or
                            in part, without OSA Consulting’ prior written permission, except for personal use or a
                            non-commercial use of unmodified content with a visible credit to OSA Consulting. <br/><br/>
                            All rights in relation to this Website are reserved and owned by OSA Consulting. <br/><br/>
                            Any links to other websites are only for the Users’ convenience and does not imply an
                            endorsement of the views, information or products provided or referred to by such
                            websites. <br/>
                            OSA Consulting does not accept liability, warranties or responsibility for the contents or
                            functioning of linked websites. <br/><br/><br/>
                        </p>
                    </Paragraph>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default LegalDisclaimer;