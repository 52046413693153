import React from 'react';
import Main from "./pages/Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.css';
import Root from "./pages/Root";
import NewsDetail from "./components/NewsDetail";
import Testimonials from "./pages/Testimonials";
import Team from "./pages/Team";
import Email from "./pages/Email";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalDisclaimer from "./pages/LegalDisclaimer";
import StaticText from "./pages/StaticText";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/legal-disclaimer" element={<LegalDisclaimer/>} />
                <Route path="/news/:id" element={<NewsDetail />} />
                <Route path="/root/admin/news" element={<Root/>} />
                <Route path="/root/admin/testimonials" element={<Testimonials/>} />
                <Route path="/root/admin/team" element={<Team/>} />
                <Route path="/root/admin/email" element={<Email/>} />
                <Route path="/root/admin/static-text" element={<StaticText/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
