import React from 'react';
import '../style.css';
import logo from '../assets/logo.png';
import linked from '../assets/linkedin.png';
import {useNavigate, useLocation} from "react-router-dom";

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handlePrivacy = () => {
        navigate(`/privacy-policy/`);
    };
    const handleLegal = () => {
        navigate(`/legal-disclaimer/`);
    };

    // Пример условного класса в зависимости от текущего пути
    const isHomePage = location.pathname === '/';
    const isPrivacyPage = location.pathname === '/privacy-policy/';
    const isLegalPage = location.pathname === '/legal-disclaimer/';

    return (
        <footer className={`footer ${isHomePage ? 'home-footer' : ''} ${isPrivacyPage ? 'privacy-footer' : ''} ${isLegalPage ? 'legal-footer' : ''}`}>
            <div className="container">
                <nav className="footer-navbar">
                    <div className="footer-logo-container">
                        <div className="footer-logo">
                            <img src={logo} alt="OSA Consulting Logo"/>
                            <span>OSA CONSULTING</span>
                        </div>
                        <span className="motto">All rights reserved</span>
                    </div>
                    <p className="address desktop">Washington D.C., USA <br/><br/> Lisbon, Portugal</p>
                    <div className="policy">
                        <a onClick={handlePrivacy} className="address privacy">Privacy Policy</a><br/><br/><br/>
                        <a onClick={handleLegal} className="address privacy">Legal Disclaimer</a>
                    </div>
                    <div className="contact">
                        <p className="address mobile">Washington D.C., USA <br/><br/> Lisbon, Portugal</p>
                        <div className="contact-items">
                            <a href="https://www.linkedin.com/company/osa-consulting-llc/" target="_blank" rel="noreferrer">
                                <img src={linked} alt=""/>
                            </a>
                        </div>
                        <a type="email" className="email">info@osaconsultingdc.com</a>
                    </div>
                </nav>
            </div>
        </footer>
    );
}

export default Footer;
