import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, Form, Input, DatePicker, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import "../style.css"
import {useNavigate} from "react-router-dom";

interface EmailItem {
    id: number;
    name: string;
}

const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const Team: React.FC = () => {
    const [news, setNews] = useState<EmailItem[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get<EmailItem[]>(`${backendUrl}/email`);
            setNews(response.data);
            setLoading(false);
        } catch (error) {
            message.error('Error fetching news');
        }
    };

    const downloadExcel = async () => {
        try {
            const response = await axios.get(`${backendUrl}/email/download`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'emails.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            message.error('Error downloading Excel file');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        }
    ];

    const handleToTeam = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/team`);
    };
    const handleToTestimonials = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/testimonials`);
    };
    const handleToNews = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/news`);
    };
    const handleToStaticText = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/static-text`);
    };

    const navigate = useNavigate();

    return (
        <div className="admin-panel-container">
            <h1>Emails</h1>
            <Button type="default" onClick={handleToTeam} style={{marginBottom: 16, marginRight: 5}}>
                Team
            </Button>
            <Button type="default" onClick={handleToTestimonials} style={{marginBottom: 16, marginRight: 5}}>
                Testimonials
            </Button>
            <Button type="default" onClick={handleToNews} style={{marginBottom: 16}}>
                News
            </Button>
            <Button type="default" onClick={handleToStaticText} style={{marginBottom: 16}}>
                Static Text
            </Button>
            <Button type="primary" onClick={downloadExcel} style={{marginBottom: 16}}>
                Download CSV
            </Button>
            <Table columns={columns} dataSource={news} rowKey="id" loading={loading}/>
        </div>
    );
};

export default Team;
