// NewsDetail.tsx
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import podcast from "../assets/podcast-icon.png";

interface NewsItem {
    id: number;
    name: string;
    date: string;
    text: string;
    imageUrl: string;
    youtubeUrl: string;
    podcast: string;
}

const backendUrl: string = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const NewsDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [newsItem, setNewsItem] = useState<NewsItem | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchNewsItem = async () => {
            try {
                const response = await axios.get<NewsItem>(`${backendUrl}/news/${id}`);
                setNewsItem(response.data);
                setLoading(false);
            } catch (error) {
                setError('Error fetching news item');
                setLoading(false);
            }
        };

        fetchNewsItem();
    }, [id]);

    if (loading) {
        return <div className="news-detail-container"><p>Loading...</p></div>;
    }

    if (error) {
        return <div className="news-detail-container"><p>{error}</p></div>;
    }

    if (!newsItem) {
        return <div className="news-detail-container"><p>News item not found</p></div>;
    }

    return (
        <div className="news-detail-container">
            <div className="news-detail">
                <h1 className="news-detail-title">{newsItem.name}</h1>
                <p className="news-detail-date">{newsItem.date}</p>
                <div className="media-link">
                    <iframe width="375" height="200" src={newsItem.youtubeUrl}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                            className="youTube"
                            style={{ display: newsItem.youtubeUrl ? 'block' : 'none' }}></iframe>
                    <br/>
                    <div className="podcast" style={{display: newsItem.podcast ? 'flex' : 'none'}}>
                        <Link to={newsItem.podcast} style={{display: "flex"}}>
                            <img src={podcast}/>
                            <p>Listen to podcast</p>
                        </Link>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{__html: newsItem.text.replace(/\n/g, '<br>')}}
                   className="news-detail-text"/>
            </div>
        </div>
    );
}

export default NewsDetail;
