import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, Form, Input, DatePicker, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import "../style.css"
import {useNavigate} from "react-router-dom";

interface TeamItem {
    id: number;
    name: string;
    position: string;
    text: string;
    imageUrl: string;
    position2: string;
    number: number;
}

const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const Team: React.FC = () => {
    const [news, setNews] = useState<TeamItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentNews, setCurrentNews] = useState<TeamItem | null>(null);
    const [fileList, setFileList] = useState<any[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get<TeamItem[]>(`${backendUrl}/team`);
            setNews(response.data);
            setLoading(false);
        } catch (error) {
            message.error('Error fetching team');
        }
    };

    const handleAddNews = () => {
        form.resetFields();
        setCurrentNews(null);
        setIsEditMode(false);
        setFileList([]);
        setIsModalVisible(true);
    };

    const handleDeleteNews = async (id: number) => {
        try {
            await axios.delete(`${backendUrl}/team/${id}`);
            message.success('Person deleted successfully');
            fetchNews();
        } catch (error) {
            message.error('Error deleting person');
        }
    };

    const handleFormSubmit = async (values: any) => {
        const {img, ...newsData} = values;

        const formData = new FormData();
        console.log('Selected Image:', img);
        // Добавляем файл изображения в FormData
        formData.append('img', fileList[0].originFileObj);

        const formattedData = {
            name: newsData.name,
            position: newsData.position,
            position2: newsData.position2,
            number: newsData.number,
            text: newsData.text,
        };
        formData.append('team', JSON.stringify(formattedData));
        console.log(formData)

        try {
            if (isEditMode && currentNews) {
                await axios.put(`${backendUrl}/team/${currentNews.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('News updated successfully');
            } else {
                await axios.post(`${backendUrl}/team`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('Person added successfully');
            }
            fetchNews();
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error saving Person');
        }
    };

    const handleUploadChange = ({fileList}: any) => {
        setFileList(fileList);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position'
        },
        {
            title: 'Position2',
            dataIndex: 'position2',
            key: 'position2'
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number'
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (imageUrl: string) => (
                <img src={imageUrl} alt="news" style={{width: '100px'}}/>
            )
        },
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text',
            render: (text: string) => (
                <div style={{maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {text}
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TeamItem) => (
                <>
                    <Button onClick={() => handleDeleteNews(record.id)} type="default">
                        Delete
                    </Button>
                </>
            )
        }
    ];

    const handleToNews = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/news`);
    };
    const handleToTestimonials = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/testimonials`);
    };
    const handleToEmail = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/email`);
    };
    const handleToStaticText = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/static-text`);
    };
    const navigate = useNavigate();

    return (
        <div className="admin-panel-container">
            <h1>Team</h1>
            <Button type="default" onClick={handleAddNews} style={{marginBottom: 16, marginRight: 5}}>
                Add Person
            </Button>
            <Button type="default" onClick={handleToNews} style={{marginBottom: 16, marginRight: 5}}>
                News
            </Button>
            <Button type="default" onClick={handleToTestimonials} style={{marginBottom: 16, marginRight: 5}}>
                Testimonials
            </Button>
            <Button type="default" onClick={handleToEmail} style={{marginBottom: 16}}>
                Email
            </Button>
            <Button type="default" onClick={handleToStaticText} style={{marginBottom: 16}}>
                Static Text
            </Button>
            <Table columns={columns} dataSource={news} rowKey="id" loading={loading}/>

            <Modal
                title={isEditMode ? 'Edit News' : 'Add News'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item name="name" label="Name"
                               rules={[{required: true, message: 'Please input the title!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="position" label="Position"
                               rules={[{required: true, message: 'Please input the title!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="position2" label="Position2">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="number" label="Number">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="text" label="Text">
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="img" label="Image"
                               rules={[{required: true, message: 'Please upload an image!'}]}>
                        <Upload
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleUploadChange}
                            listType="picture"
                        >
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" htmlType="submit">
                            {isEditMode ? 'Update' : 'Add'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Team;
