import React, {useEffect, useState} from 'react';
import {Table, Button, Modal, Form, Input, DatePicker, Upload, message} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import "../style.css"
import {useNavigate} from "react-router-dom";

interface NewsItem {
    id: number;
    name: string;
    date: string;
    text: string;
    imageUrl: string;
    youtubeUrl: string;
    podcast: string;
}

const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const Root: React.FC = () => {
    const [news, setNews] = useState<NewsItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentNews, setCurrentNews] = useState<NewsItem | null>(null);
    const [fileList, setFileList] = useState<any[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get<NewsItem[]>(`${backendUrl}/news`);
            setNews(response.data);
            setLoading(false);
        } catch (error) {
            message.error('Error fetching news');
        }
    };

    const handleAddNews = () => {
        form.resetFields();
        setCurrentNews(null);
        setIsEditMode(false);
        setFileList([]);
        setIsModalVisible(true);
    };

    const handleEditNews = (record: NewsItem) => {
        setCurrentNews(record);
        form.setFieldsValue({
            name: record.name,
            text: record.text,
            youtubeUrl: record.youtubeUrl,
            podcast: record.podcast,
        });
        setIsEditMode(true);
        setIsModalVisible(true);
    };

    const handleDeleteNews = async (id: number) => {
        try {
            await axios.delete(`${backendUrl}/news/${id}`);
            message.success('News deleted successfully');
            fetchNews();
        } catch (error) {
            message.error('Error deleting news');
        }
    };

    const handleFormSubmit = async (values: any) => {
        const {image, ...newsData} = values;

        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('img', fileList[0].originFileObj);
        }

        const formattedData = isEditMode ? {
            name: newsData.name,
            text: newsData.text,
            youtubeUrl: newsData.youtubeUrl,
            podcast: newsData.podcast,
        } : {
            name: newsData.title,
            date: values.date.format('YYYY-MM-DD'), // Пример формата даты и названия поля
            text: newsData.text,
            youtubeUrl: newsData.youtubeUrl,
            podcast: newsData.podcast,
        };
        formData.append('news', JSON.stringify(formattedData));

        try {
            if (isEditMode && currentNews) {
                await axios.put(`${backendUrl}/news/${currentNews.id}`, formattedData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                message.success('News updated successfully');
            } else {
                await axios.post(`${backendUrl}/news`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                message.success('News added successfully');
            }
            fetchNews();
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error saving news');
        }
    };

    const handleUploadChange = ({fileList}: any) => {
        setFileList(fileList);
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (imageUrl: string) => (
                <img src={imageUrl} alt="news" style={{width: '100px'}}/>
            )
        },
        {
            title: 'Youtube',
            dataIndex: 'youtubeUrl',
            key: 'youtubeUrl'
        },
        {
            title: 'Podcast',
            dataIndex: 'podcast',
            key: 'podcast'
        },
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text',
            render: (text: string) => (
                <div style={{maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {text}
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: NewsItem) => (
                <>
                    <Button onClick={() => handleEditNews(record)} type="default" style={{marginRight: 8}}>
                        Edit
                    </Button>
                    <Button onClick={() => handleDeleteNews(record.id)} type="default">
                        Delete
                    </Button>
                </>
            )
        }
    ];

    const handleToTeam = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/team`);
    };
    const handleToTestimonials = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/testimonials`);
    };
    const handleToEmail = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/email`);
    };
    const handleToStaticText = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/static-text`);
    };

    const navigate = useNavigate();


    return (
        <div className="admin-panel-container">
            <h1>News</h1>
            <Button type="default" onClick={handleAddNews} style={{marginBottom: 16, marginRight: 5}}>
                Add News
            </Button>
            <Button type="default" onClick={handleToTeam} style={{marginBottom: 16, marginRight: 5}}>
                Team
            </Button>
            <Button type="default" onClick={handleToTestimonials} style={{marginBottom: 16, marginRight: 5}}>
                Testimonials
            </Button>
            <Button type="default" onClick={handleToStaticText} style={{marginBottom: 16, marginRight: 5}}>
                Static Text
            </Button>
            <Button type="default" onClick={handleToEmail} style={{marginBottom: 16}}>
                Email
            </Button>
            <Table columns={columns} dataSource={news} rowKey="id" loading={loading}/>

            <Modal
                title={isEditMode ? 'Edit News' : 'Add News'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    {isEditMode ? <Form.Item name="name" label="Title"
                                             rules={[{required: true, message: 'Please input the title!'}]}>
                        <Input/>
                    </Form.Item> : <Form.Item name="title" label="Title"
                                              rules={[{required: true, message: 'Please input the title!'}]}>
                        <Input/>
                    </Form.Item>}
                    {isEditMode ? " " : <Form.Item name="date" label="Date"
                                                   rules={[{required: true, message: 'Please select the date!'}]}>
                        <DatePicker format="DD.MM.YY"/>
                    </Form.Item>}
                    <Form.Item name="text" label="Text" rules={[{required: true, message: 'Please input the text!'}]}>
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="youtubeUrl" label="YouTube">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="podcast" label="Podcast">
                        <Input/>
                    </Form.Item>
                    {isEditMode ? " " : <Form.Item name="image" label="Image"
                                                   rules={[{required: true, message: 'Please upload an image!'}]}>
                        <Upload
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleUploadChange}
                            listType="picture"
                        >
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>}
                    <Form.Item>
                        <Button type="default" htmlType="submit">
                            {isEditMode ? 'Update' : 'Add'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Root;
