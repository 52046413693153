import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Typography} from "antd";

const {Title, Paragraph, Text, Link} = Typography;


const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <Header/>
            <div className="container">
                <div style={{textAlign: "left"}}>
                    <h1 style={{fontSize: "32px", marginTop: "30px", marginBottom: "20px"}}>Media Data Protection
                        Policy</h1>
                    <h2 style={{fontSize: "32px", marginTop: "10px", marginBottom: "20px"}}>Purpose</h2>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "10px"}}>OSA Consulting respects the privacy of all
                            users of its website or other ways we may
                            communicate. <br/>
                            Personal information is treated with the highest confidentiality. <br/>
                            The purpose of this ‘’Media Data Protection Policy’’ is to inform about: <br/>
                        </p>

                        <p style={{fontSize: "16px", marginLeft: "20px"}}>
                            • What sort of data we collect when you use our website or other ways of communication
                        </p>
                        <p style={{fontSize: "16px", marginLeft: "20px"}}>
                            • Why it is collected
                        </p>
                        <p style={{fontSize: "16px", marginLeft: "20px"}}>
                            • How we use it to for your benefit.
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>This ‘’Media Data Protection Policy’’ is
                            applicable to all services provided by OSA Consulting. However, our company is not
                            responsible
                            for the Privacy Policy of any other websites or sources we interact with. <br/>
                            By making use of this tool, you accept the content of our ‘’Media Data Protection Policy’’.
                        </p>
                    </Paragraph>

                    <h2 style={{fontSize: "32px", marginTop: "10px", marginBottom: "20px"}}>Contact</h2>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "10px"}}>You may contact us concerning this ‘’Media
                            Data Protection Policy’’ by using the following e-mail address: <br/>
                        </p>

                        <p style={{fontSize: "16px"}}>
                            <Link href="mailto:info@osaconsultingdc.com">info@osaconsultingdc.com</Link>
                        </p>

                        <p style={{fontSize: "16px"}}>
                            or <br/>
                            by any of the contact options shown on this website. <br/>
                            On this matter, the contact person is: <br/>
                            Ms. Jacqui Forte – Business Manager.
                        </p>
                    </Paragraph>

                    <h2 style={{fontSize: "32px", marginTop: "10px", marginBottom: "20px"}}>What sort of information is
                        collected?</h2>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Personal Information<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            OSA Consulting collects the ‘’Personal Information’’ that you choose to enter on various
                            pages of our website, or that you may choose to give us in other ways (such as by email,
                            mail, mailing lists system, or by telephone). <br/><br/>
                            As an example, you may be asked to provide “Personal Information” when filling in an
                            application form, initiating or processing a contract, supplying information in connection
                            with a survey or signing up for mailings. <br/><br/>
                            Any provided data will be processed in accordance with this ‘’Media Data Protection
                            Policy’’, which is in line with the General Data Protection Regulation (GDPR). <br/><br/>
                            In case you no longer agree with the ‘’Terms and Conditions’’ of our ‘’Media Data Protection
                            Policy’’ we recommend to inform us about your withdrawal or to make use of your rights,
                            under the GDPR Article 15-22, by email to: <br/><br/>

                            <p style={{fontSize: "16px"}}>
                                <Link href="mailto:info@osaconsultingdc.com">info@osaconsultingdc.com</Link>
                            </p> <br/>

                            A list of your rights as a data subject can be found in this ‘’Media Data Protection
                            Policy’’. However, data that is used for legal contracts will only be deleted in accordance
                            with national laws and retention periods. <br/><br/>
                            The collected categories of ‘’Personal Information’’ are as follows: <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginLeft: "20px"}}>
                            • First and last name <br/>
                            • Address <br/>
                            • E-mail address and mobile phone number <br/>
                            • Education <br/>
                            • Domain of expertise <br/>
                            • Company names <br/>
                            • Motivation letter and curriculum vitae <br/>
                            • Other non-specified types of personal information <br/>
                        </p> <br/><br/>

                        <p style={{fontSize: "16px"}}>
                            If you do not wish to share certain information, you may not be able to take advantage of
                            all our services and features. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Automate
                                Information</p>
                            When you visit a website, you disclose certain information, such as your Internet Protocol
                            (IP) address and the time of your visit. This site, like many other sites, records this
                            basic information about visits. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Self-Sharing
                                Information</p>
                            You can choose (e.g. via buttons on OSA Consulting website(s)) to share information via
                            social networks such as LinkedIn or Twitter. For this functionality, data is provided to the
                            selected social media platform. These are then responsible for your data and only their
                            Privacy Policy is applicable. <br/><br/>
                        </p>
                    </Paragraph>

                    <h2 style={{fontSize: "32px", marginTop: "10px", marginBottom: "20px"}}>Functionalities</h2>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Cookies<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            Cookies are information that are transferred to your computer from a webserver. <br/><br/>
                            Cookies are used to store visitor preferences, record session information, record
                            user-specific information on the pages users access to or visit, alert visitors to new areas
                            that we think might be of their interest when they return to our website, record past
                            activity on the website in order to provide better service when visitors return to our
                            website and to customise web page content based on visitors’ browser type or other
                            information that the visitors send. <br/><br/>
                            Most browsers are set up to accept cookies, but you can change your settings to be notified
                            when you receive a new cookie or you can change your settings to refuse cookies. <br/><br/>
                            The following types of cookies are used on this website: <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Functional
                                cookies<br/>
                            </p>

                            These are cookies that are necessary for this Expression Engine site to function. Without
                            these functional cookies, this site does not work optimally. According to the cookie law, no
                            permission is required for this category of cookies.

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Functional
                                cookies<br/>
                            </p>

                            These are cookies that are necessary for this Expression Engine site to function. Without
                            these functional cookies, this site does not work optimally. According to the cookie law, no
                            permission is required for this category of cookies. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Analysis/tracking
                                cookies<br/>
                            </p>

                            This site works with Google Analytics and uses these analytical cookies so that we can track
                            visitors’ location, the operating system they use, the number of views per article etc. This
                            information is of great importance to us; on the basis of this measurement data, we can
                            optimise the website and make it more user-friendly. The collected data are not used for any
                            other purpose or made available to Third Parties. Cookies do not contain any other personal
                            data, therefore OSA Consulting cannot use cookies to identify you. We always comply with the
                            GDPR guidelines. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Social media/embedded
                                content cookies<br/>
                            </p>

                            Social media buttons and embedded content from social media sites such as YouTube, LinkedIn
                            and other platforms often contain cookies. We do not know what these platforms do with these
                            cookies. We have no influence on the placing or not of these cookies. For the placement of
                            these buttons and embedded content (including the cookies) we need your permission according
                            to the cookie legislation. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Turn off cookies<br/>
                            </p>

                            If you do not want to install cookies, you can turn this setting off in your browser.
                            Consult the help function and/or the settings of your browser: Chrome, Firefox and Safari.
                            Pay attention! If you disable these cookies in your browser, you cannot use all the
                            functionalities of this site. <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Use of Google
                                Analytics<br/>
                            </p>

                            This website uses functions of the web analytics service Google Analytics. These are text
                            files that are stored on your computer and enable analysis of your use of the website. The
                            information generated by the cookie about your use of this website are usually transferred
                            to a server of Google in the USA and stored there. In case of activation of IP anonymisation
                            on this website, your IP address from Google will be truncated within Member States of the
                            European Union or other parties to the Agreement on the European Economic Area. Only in
                            exceptional cases is the full IP address transmitted to a Google server in the US and
                            shortened there. On behalf of the operator of this website, Google will use this information
                            to evaluate your use of the website, compiling reports on website activity. It is an
                            undertaking of Google Analytics that your browser IP address cannot be merged with other
                            data of Google. You can refuse the use of cookies by selecting the appropriate settings on
                            your browser software; however, we point out that you might not be able to use all features
                            of this website. You can also prevent the data generated by the cookie and relating to your
                            usage of the website (incl. your IP address) going to Google and the processing of these
                            data by Google, by downloading the following browser plugin and install it: <br/>

                            <p style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.tools.google.com/dlpage/gaoptout">www.tools.google.com/dlpage/gaoptout</Link>.
                            </p>

                            <em>Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043. Google Analytics uses
                                “cookies”</em> <br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Privacy Policy for
                                the use of LinkedIn<br/>
                            </p>

                            Our site uses functions of the network LinkedIn. Every time you visit our pages that contain
                            features of LinkedIn, a connection to servers from LinkedIn is built. LinkedIn is informed
                            that you have visited our website with your IP address. If you click the “Recommend” button
                            (LinkedIn) and are logged into your account with LinkedIn, it is possible to associate your
                            visit to our website with your account. We point out that we, as providers of the site, have
                            no knowledge of the content of the transmitted data and the use of them through
                            LinkedIn. <br/><br/>
                            For further details, please check the Privacy Policy of <br/>

                            <p style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.linkedin.com/legal/privacy-policy">LinkedIn:
                                    www.linkedin.com/legal/privacy-policy</Link>.
                            </p>

                            <em>Provider is the LinkedIn Corporation 2029 Stierlin Court, Mountain View, CA 94043</em>
                            <br/><br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Privacy Policy for
                                the Use of Talentsquare<br/>
                            </p>

                            Functions of the Talentsquare service are integrated on our website. Through the use of
                            Talentsquare you will be able to easily apply to our jobs. This data is also transmitted to
                            Talentsquare. We point out that we as providers of the site, have no knowledge of the
                            content of the transmitted data and use through Talentsquare.<br/><br/>
                            For further details, please check the terms and conditions of Talentsquare on <br/>

                            <p style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.talentsquare.com/applicant-terms-use/">https://www.talentsquare.com/applicant-terms-use/</Link>.
                            </p>

                            For further details, please check the privacy policy of Talentsquare on <br/>

                            <p style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.talentsquare.com/privacy-policy-for-applicants/">https://www.talentsquare.com/privacy-policy-for-applicants/</Link>.
                            </p>

                            <em>Talentsquare Inc., Rue de Maustichy 10, 1460 Ittre, Belgium</em>
                            <br/><br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Privacy Policy for
                                the Use of Twitter<br/>
                            </p>

                            Functions of the Twitter service are integrated on our website. Through the use of Twitter
                            and the function “Re-Tweet” the web pages you visit are linked to your Twitter account and
                            made known to other users. This data is also transmitted to Twitter. We point out that we,
                            as providers of the site, have no knowledge of the content of the transmitted data and use
                            through Twitter. <br/><br/>
                            For further details, please check the Privacy Policy of Twitter on

                            <a style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.twitter.com/privacy"> www.twitter.com/privacy</Link>.
                            </a> <br/><br/>
                            It is feasible to change your account privacy settings on Twitter, on <br/>

                            <a style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.twitter.com/account/settings"> www.twitter.com/account/settings</Link>.
                            </a> <br/><br/>

                            <em>Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA</em>
                            <br/><br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Privacy Policy for
                                the use of ‘’YouTube’’<br/>
                            </p>

                            Our website uses ‘’YouTube’’ for integrating video material. Like most websites ‘’YouTube’’
                            uses cookies to gather information about the audience of videos. ‘’YouTube’’ uses cookies
                            among other things for detecting reliable video statistics, to prevent fraud and to improve
                            the user-friendliness.<br/><br/>
                            For further details, please check the Privacy Policy of ‘’YouTube’’ on: <br/>

                            <a style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.youtube.com/t/privacy_at_youtube"> www.youtube.com/t/privacy_at_youtube</Link>.
                            </a> <br/><br/>

                            <em>Provider is the YouTube LLC, 901 Cherry Ave, SAN Bruno, CA 94066 USA</em>
                            <br/><br/><br/>

                            <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Privacy Policy for
                                the Use of Facebook<br/>
                            </p>

                            Functions of the Facebook service are integrated on our website. Through the use of Facebook
                            and the function “Share” the web pages you visit are linked to your Facebook account and
                            made known to other users. This data is also transmitted to Facebook. We point out that we,
                            as providers of the site, have no knowledge of the content of the transmitted data and use
                            through Facebook.<br/><br/>
                            For further details, please check the Privacy Policy of Facebook on <br/>

                            <a style={{fontSize: "16px"}}>
                                <Link
                                    href="https://www.facebook.com/privacy/center/"> www.facebook.com/privacy/center/</Link>.
                            </a> <br/><br/>

                            <em>Meta 1 Hacker Way Menlo Park, California 94025</em>
                            <br/><br/><br/>
                        </p>
                    </Paragraph>

                    <h2 style={{fontSize: "32px", marginTop: "10px", marginBottom: "20px"}}>Legal Baseline for Data
                        Processing</h2>
                    <Paragraph>
                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Legal<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            The above-mentioned personal data are handled on the basis of Art. 6 I a), b) or f) EU Data
                            Protection Regulation. <br/><br/>
                            The sole legitimate motivation to process personal data, on the basis of Art. 6 I lit f),
                            remain to enable OSA Consulting to have the most suitable candidate for the respective
                            position and to offer the best possible position to each candidate as well as to communicate
                            some events (e.g. invitation, press releases) linked to our core activities. <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>How and under which
                            circumstances is the information used?<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            The collected information is used for administering our business activities. <br/><br/>
                            Regarding, applicants for employment, we may also forward your data to our
                            customers. <br/><br/>
                            We do not use the information for Third Party marketing purposes or to unsolicited mailing
                            lists. <br/><br/>
                            Data will not be forwarded to Third Parties for marketing purposes. <br/><br/><br/>

                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>How do we protect the
                            provided information?<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            OSA Consulting employs technical and organisational security measures, in order to protect
                            any personal data administered by us against accidental or intentional manipulation, loss,
                            destruction or unauthorised access by Third Parties. Our security measures are regularly
                            reviewed and upgraded. <br/><br/>
                            These ‘’Data Protection Guidelines’’ are applied to protect the confidentiality of your
                            personal information. In addition, our business practices are updated periodically for
                            compliance with policies and procedures governing the security and confidentiality of our
                            information. Our business practices limit employee access to confidential information and
                            limit the use and disclosure of such information to authorised persons. <br/><br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Who can have access to
                            the provided information?<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            Information about our users and customers are necessary for our company. <br/><br/>
                            Access to your information is never provided to Third Parties apart from other OSA
                            Consulting offices and our clients. <br/><br/>
                            We may also disclose your information in response to a court order and we may disclose your
                            information when we believe we are required to do so by law. In this case, we may not
                            provide you with notice prior to disclosure. <br/><br/><br/>

                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Rights of the Data
                            Subject, how can you access the provided information?<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            Article 15-22 of the GDPR allows you to have the right to obtain from OSA Consulting
                            confirmation as to whether or not personal data concerning you is being processed. The
                            following can be requested: <br/><br/>

                            <p style={{fontSize: "16px", marginLeft: "20px"}}>
                                • To request rectification or erasure of personal data or the restriction of processing
                                of
                                your personal data <br/>
                                • To object to such processing <br/>
                                • To lodge a complaint with a supervisory authority <br/>
                                • To demand rectification of inaccurate personal data <br/>
                                • To have incomplete personal data completed <br/>
                                • To receive your personal data, which you have provided to us. <br/>
                            </p> <br/>

                            This request should be sent to: <br/><br/>
                            <Link href="mailto:info@osaconsultingdc.com">info@osaconsultingdc.com</Link>. <br/><br/>
                            According to Art. 77 EU Data Protection Regulation you have the right to lodge a
                            complaint
                            with a supervisory authority, in particular in the member state of his or her habitual
                            residence, place of work or place of the alleged infringement if you consider that the
                            processing of personal data relating to you infringes this regulation. <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Declaration of
                            consent<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            By using our website or other described ways of communication, you consent to the collection
                            and use of your personal information as described in this Media Data Protection
                            Policy. <br/><br/>
                            By providing your personal data either by using our website or by sending us information by
                            using the means of electronic communication, you consent to the processing of your personal
                            data in accordance with our ‘Media Data Protection Policy’. <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Personal Data provided by
                            candidates for recruitment purposes<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            Candidates may provide their applications containing personal data i.e. via our website, by
                            e-mail or by standard mail. <br/><br/>
                            We reserve the right to store the data provided by you for a period of up to 24 months after
                            we have received the application. <br/><br/>
                            Data are stored on the basis of Art. 6 I a), b) and f) EU Data Protection
                            Regulation. <br/><br/>
                            Our interests in storing and processing your data is to offer you a position in order to
                            place you in a vacant position or to provide you as candidate to our customers. <br/><br/>
                            It is your right to withdraw your consent of this storage at any time and to demand the
                            deleting of your personal data. <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Data Deletion<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            Personal data is deleted after expiry of the statutory or contractual retention
                            periods. <br/><br/>
                            Applications sent via e-mail are permanently deleted on your explicit request. <br/><br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "15px", fontWeight: "600"}}>Changes to Media Data
                            Protection Policy<br/>
                        </p>

                        <p style={{fontSize: "16px", marginBottom: "10px"}}>
                            In case of modifications implemented in our ‘Media Data Protection Policy’, we shall
                            immediately upload those changes to our website. <br/><br/>
                            Upon release, these changes shall be immediately applicable, unless otherwise
                            mentioned. <br/><br/>
                        </p>
                    </Paragraph>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;