import React from 'react';
import '../style.css';


interface BlockProps {
    id: string;
    title: string;
    content: string[];
    isOpen: boolean;
    onClick: () => void;
}

const Block: React.FC<BlockProps> = ({ id, title, content, isOpen, onClick }) => {
    // Разделение контента на два столбца
    const firstColumnContent = content.slice(0, 5);
    const secondColumnContent = content.slice(5);

    return (
        <div className={`block ${isOpen ? 'open' : ''}`} id={id} onClick={onClick}>
            <div className="block-title">{title}</div>
            <div className="block-content">
                {isOpen && (
                    <>
                        <h2>{title}</h2>
                        <div className="block-text">
                            <div className="column">
                                {firstColumnContent.map((text, index) => (
                                    <p key={index}>{text}</p>
                                ))}
                            </div>
                            <div className="column seccond">
                                {secondColumnContent.map((text, index) => (
                                    <p key={index}>{text}</p>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Block;
