import React, { useState, useEffect } from 'react';
import '../style.css';


interface ResponsiveImageProps {
    imgSmall: string;
    imgMedium: string;
    imgLarge: string;
    alt: string;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ imgSmall, imgMedium, imgLarge, alt }) => {
    const [currentImage, setCurrentImage] = useState<string>(imgSmall);

    const updateImage = () => {
        if (window.innerWidth < 600) {
            setCurrentImage(imgSmall);
        } else if (window.innerWidth < 768) {
            setCurrentImage(imgMedium);
        } else {
            setCurrentImage(imgLarge);
        }
    };

    useEffect(() => {
        updateImage();
        window.addEventListener('resize', updateImage);
        return () => window.removeEventListener('resize', updateImage);
    }, []);

    return <img src={currentImage} className="inform-image" alt={alt} />;
};

export default ResponsiveImage;
