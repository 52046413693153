import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Layout, Menu, Table, Button, Space, Form, Input, message} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {StaticTextModel} from '../models/StaticTextModel';
import {useNavigate} from "react-router-dom";

const {Header, Content, Footer} = Layout;
const backendUrl = 'https://osaconsultingdc.com/api'
// const backendUrl: string = 'http://146.59.232.43/api'
// const backendUrl: string = 'http://localhost:5050/api'

const StaticText: React.FC = () => {
    const [staticTexts, setStaticTexts] = useState<StaticTextModel[]>([]);
    const [editingStaticText, setEditingStaticText] = useState<StaticTextModel | null>(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchStaticTexts();
    }, []);

    const fetchStaticTexts = async () => {
        try {
            const response = await axios.get(`${backendUrl}/static-texts`);
            setStaticTexts(response.data);
        } catch (error) {
            message.error('Failed to fetch static texts');
        }
    };

    const handleEdit = (id: number) => {
        const staticText = staticTexts.find(text => text.id === id);
        setEditingStaticText(staticText || null);
        form.setFieldsValue(staticText || {});
        setIsFormVisible(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await axios.delete(`${backendUrl}/statictexts/${id}`);
            fetchStaticTexts();
            message.success('Static text deleted successfully');
        } catch (error) {
            message.error('Failed to delete static text');
        }
    };

    const handleAddNew = () => {
        setEditingStaticText(null);
        form.resetFields();
        setIsFormVisible(true);
    };

    const handleSubmit = async (values: StaticTextModel) => {
        try {
            if (editingStaticText) {
                await axios.put(`${backendUrl}/static-texts/${editingStaticText.id}`, values);
                message.success('Static text updated successfully');
            } else {
                await axios.post(`${backendUrl}/static-texts`, values);
                message.success('Static text created successfully');
            }
            setIsFormVisible(false);
            fetchStaticTexts();
        } catch (error) {
            message.error('Failed to save static text');
        }
    };

    const handleCancel = () => {
        setIsFormVisible(false);
    };

    const columns: ColumnsType<StaticTextModel> = [
        {title: 'Top Subscribe Title', dataIndex: 'topSubscribeTitle', key: 'topSubscribeTitle'},
        {title: 'Top Subscribe Text', dataIndex: 'topSubscribeText', key: 'topSubscribeText'},
        {title: 'Scope Title', dataIndex: 'scopeTitle', key: 'scopeTitle'},
        {title: 'Scope Text', dataIndex: 'scopeText', key: 'scopeText'},
        {title: 'Services Title', dataIndex: 'servicesTitle', key: 'servicesTitle'},
        {title: 'Services Text', dataIndex: 'servicesText', key: 'servicesText'},
        {title: 'News Title', dataIndex: 'newsTitle', key: 'newsTitle'},
        {title: 'News Text', dataIndex: 'newsText', key: 'newsText'},
        {title: 'Testimonials Title', dataIndex: 'testimonialsTitle', key: 'testimonialsTitle'},
        {title: 'Testimonials Text', dataIndex: 'testimonialsText', key: 'testimonialsText'},
        {title: 'Team Title', dataIndex: 'teamTitle', key: 'teamTitle'},
        {title: 'Team Text', dataIndex: 'teamText', key: 'teamText'},
        {title: 'Bottom Subscribe Title', dataIndex: 'bottomSubscribeTitle', key: 'bottomSubscribeTitle'},
        {title: 'Bottom Subscribe Title', dataIndex: 'bottomSubscribeText', key: 'bottomSubscribeText'},
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => handleEdit(record.id!)}>Edit</Button>
                </Space>
            ),
        },
    ];

    const handleToTeam = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/team`);
    };
    const handleToTestimonials = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/testimonials`);
    };
    const handleToEmail = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/email`);
    };
    const handleToStaticText = () => {
        // Переходим на страницу с информацией о новости при клике на новость
        navigate(`/root/admin/news`);
    };

    const navigate = useNavigate();

    return (
        <Layout className="layout">
            <div className="admin-panel-container">
                <Button type="default" onClick={handleToTeam} style={{marginBottom: 16, marginRight: 5}}>
                    Team
                </Button>
                <Button type="default" onClick={handleToTestimonials} style={{marginBottom: 16, marginRight: 5}}>
                    Testimonials
                </Button>
                <Button type="default" onClick={handleToStaticText} style={{marginBottom: 16, marginRight: 5}}>
                    News
                </Button>
                <Button type="default" onClick={handleToEmail} style={{marginBottom: 16}}>
                    Email
                </Button>
                <Content style={{padding: '0 50px'}}>
                    <div className="site-layout-content">
                        {isFormVisible ? (
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleSubmit}
                            >
                                <Form.Item name="topSubscribeTitle" label="Top Subscribe Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="topSubscribeText" label="Top Subscribe Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="scopeTitle" label="Scope Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="scopeText" label="Scope Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="servicesTitle" label="Services Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="servicesText" label="Services Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="newsTitle" label="News Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="newsText" label="News Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="testimonialsTitle" label="Testimonials Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="testimonialsText" label="Testimonials Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="teamTitle" label="Team Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="teamText" label="Team Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item name="bottomSubscribeTitle" label="Bottom Subscribe Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="bottomSubscribeText" label="Bottom Subscribe Text">
                                    <Input.TextArea/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    <Button onClick={handleCancel} style={{marginLeft: '8px'}}>
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : (
                            <div>
                                {/*<Button type="primary" onClick={handleAddNew}>*/}
                                {/*    Add New Static Text*/}
                                {/*</Button>*/}
                                <Table columns={columns} dataSource={staticTexts} rowKey="id"/>
                            </div>
                        )}
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>Admin Panel ©2024</Footer>
            </div>
        </Layout>
    );
};

export default StaticText;
